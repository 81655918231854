<div class="welcomeText">
    <h3 style="width: 100%;">Unsere Preise für <b><span>Privatpatienten / Selbstzahler</span></b></h3>
    
    <div class="price-list" style="padding-top: 16px;">
        <div class="price-item" *ngFor="let item of priceList">
          <div class="item-description">{{item.description}}</div>
          <div class="item-price">{{item.price}}</div>
        </div>
      </div>
    <div class="TeamButton" style="padding-top: 50px;">
        <a aria-label="Preis-Seite" routerLink="/preise">Weiter zu den Preisen</a>
    </div>
    
</div>