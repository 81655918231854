<div class="overdiv">
    <div class="firstView">
        <img src="./assets/img/first-pic.webp" alt="">
        <div class="spandesign">
            <span>
                <p >Osteopathie, Physiotherapie & Naturheilkunde</p>
                <p class="Title-city">St. Tönis</p>
            </span>
            <div class="arrow">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>


    
    <div style="width: 100%; display: flex; justify-content: center; flex-direction: column; align-items: center;">
        <div class="welcomeText">
            <h3>Herzlich Willkommen bei <b><span>Osteo</span>medica</b></h3>
            <span class="textbox destop-text">
                <p style="padding: 0;margin: 0;">
                Bei Osteomedica, der Praxis für Osteopathie, Physiotherapie und Heilkunde im Herzen von St.Tönis, ist es unser besonderes Anliegen den Menschen nach einem ganzheitlichen Ansatz zu behandeln und seine Beschwerden auf allen Ebenen zu lindern.
                </p>
            </span>
            <br>
            <br>
            <span class="textbox destop-text" >
                <p style="padding: 0;margin: 0;">
                Um den Prozess der Heilung beim Patienten umfassend zu aktivieren, nutzen wir Untersuchungs- und Behandlungstechniken aus dem Bereich der Osteopathie sowie der Naturheilkunde und nehmen auch Rücksicht auf die psychoemotionale und seelische Komponente über Behandlungsansätze aus dem Bereich der energetischen Heilweisen.
                </p>
            </span>
        </div>
        <div  class="welcomeText" style=" margin-top: 30x; ">
            <h3>Unser <b><span>Team</span></b></h3>
        </div>
        
        <div class="welcomeText ">
            <!-- >Animation OLD -->
            <!-- <div class="addDestopTeam">
                class="nameBox"
                <app-show-team style="width: 100%; display: flex; align-items: center; flex-direction: column;"></app-show-team>
                <app-stefan-pre style="width: 100%; "></app-stefan-pre>
            </div> -->
            <div class="NEWWRAPPER">
                <div *ngFor="let item of names; let i = index" class="item-container" [ngStyle]="{'flex-direction': i % 2 === 0 ? 'row' : 'row-reverse'}">
                  <div class="item-content">
                    <div class="fade-up-box">
                      <img class="person-image" [src]="'./assets/img/people/' + item.name.split(' ')[0] + '.webp'" alt="{{ item.name }}">
                      <div class="nameBox"><span>{{ item.name }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              
            <div class="TeamButton">
                <a aria-label="Team-Seite" routerLink="/team">Werde Teil des Teams</a>
            </div>
        </div>
        <div class="welcomeText">
            <h3>Unsere <b><span>Leistungen</span></b></h3>
        </div>
        <div class="welcomeText" style="width: calc(100% - 48px);display: flex; justify-content: center; align-items: center; flex-direction: column;">
            
            <div class="Main-picture-container">
                <img *ngFor="let image of images; let i = index"  
                     [src]="image" 
                     [alt]="'Image ' + i" 
                     [class.active]="i === currentImageIndex" 
                     [class.right]="imagesstyle[i] == 'right'"
                     (load)="nextImage();"
                />
                
            </div>
            <div class="nameBoxForposition"><span>{{currentText}}</span></div>
            <div class="wrapper"> 
                <div class="container">
                    <input type="radio" name="slide" id="c1" checked>
                    <label for="c1" class="card" (click)="c1()">
                        <div class="row">
                            <div class="icon"></div>
                            <div class="description">
                                <h4>Osteopathie</h4>
                            </div>
                        </div>
                    </label>
                    <input type="radio" name="slide" id="c2" >
                    <label for="c2" class="card" (click)="c2()">
                        <div class="row">
                            <div class="icon"></div>
                            <div class="description">
                                <h4>Physiotherapie</h4>
                            </div>
                        </div>
                    </label>
                    <input type="radio" name="slide" id="c3" >
                    <label for="c3" class="card" (click)="c3()">
                        <div class="row">
                            <div class="icon"></div>
                            <div class="description">
                                <h4>Heilkunde</h4>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div class="TeamButton">
                <a aria-label="Unsere-Leistungen-Seite" routerLink="/leistungen">Erfahre mehr darüber</a>
            </div>
        </div>
        <div class="welcomeText" >
            <h3>Unsere <b><span>Bürozeiten</span></b></h3>
            <div class="imageDiv" >
                <!-- <div class="imageDivC"  style="width: 100% ;display: flex; justify-content: center; align-items: center; flex-direction: column; overflow-y: hidden;">
                    <img data-aos="fade-up" class="openOursImage" src="./assets/img/people/Siglinde.webp" alt="">
                </div> -->
                <div style="font-weight: 200; text-align: center; padding-top: 20px;">
                    <Span><span style="color: rgb(50, 100, 50);">Montag</span> von 09:00 bis 13:00 Uhr</Span><br>
                    <Span><span style="color: rgb(50, 100, 50);">Dienstag</span> von 09:00 bis 13:00 Uhr</Span><br>
                    <Span><span style="color: rgb(50, 100, 50);">Mittwoch</span> von 09:00 bis 13:00 Uhr</Span><br>
                    <Span><span style="color: rgb(50, 100, 50);">Donnerstag</span> von 09:00 bis 13:00 Uhr</Span><br>
                    <Span><span style="color: rgb(50, 100, 50);">Freitag</span> von 09:00 bis 13:00 Uhr</Span><br>
                    <Span><span style="color: rgb(50, 100, 50);">Samstag</span> & <span style="color: rgb(50, 100, 50);">Sonntag</span> geschlossen</Span><br>
                </div>
            </div>
        </div>
        <app-contact-pre></app-contact-pre>
        <app-price-pre></app-price-pre>
    </div>
    
    
    
</div>