<div class="welcomeText">
    <h3 style="width: 100%;">Zum <b><span>Kontakt</span></b></h3>
    <div class="imageDiv">
        <div class="imageDivC" style="width: 100% ;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <img src="./assets/img/people/Siglinde.webp" data-aos="fade-up" alt="">
        </div>
        
        <div class="text" style="font-weight: 200;" >
            <span>Fragen? Anregungen? Wir sind hier, um zu helfen!</span>
            <br>
            <span>Ruf uns an unter <a aria-label="Telefonnummer" style="color: rgb(50, 100, 50)" href="tel:021519424700">02151-9424700</a> oder schreib uns über unser Kontaktformular.</span>
            <br>
            <span>Wir freuen uns darauf, von dir zu hören!</span>
            <br>
        </div>
    </div>
    
    <div class="TeamButton" style="padding-top: 50px;">
        <a aria-label="Weiter zum Kontaktformular" routerLink="/kontakt">Weiter zum Kontaktformular</a>
    </div>
    
</div>