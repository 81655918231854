<div class="resp-div">
    <div class="nav-div">
        <img routerLink="/" src="./assets/img/osteomedica-logo.webp" alt="">
        <div class="nav-mobile" (click)="switch(); $event.stopPropagation();">
            <div id="webapp_cover">
                <div id="menu_button">
                    <input type="checkbox" id="menu_checkbox" [checked]="isMenuChecked">
                    <label for="menu_checkbox" id="menu_label" (click)="$event.stopPropagation();">
                        <div  id="menu_text_bar"></div>
                    </label>
                </div>
            </div>
        </div>
        <div class="destop-bar">
            <a aria-label="Start-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/" [routerLinkActive]="'active-link'" [routerLinkActiveOptions]="{ exact: true }">Home</a>
            <a aria-label="Leistung-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/leistungen" [routerLinkActive]="'active-link'" href="">Leistungen</a>
            <a aria-label="Preis-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/preise" [routerLinkActive]="'active-link'" href="">Preise</a>
            <a aria-label="Team-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/team" [routerLinkActive]="'active-link'" href="">Team</a>
            <a aria-label="Kontakt-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/kontakt" [routerLinkActive]="'active-link'" href="">Kontakt</a>
        </div>
    </div>
</div>


<div (click)="switch(); $event.stopPropagation();" *ngIf="isMenuOpen" class="mobile-menu">
    <div>
        <a aria-label="Start-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/" [routerLinkActive]="'active-link'" [routerLinkActiveOptions]="{ exact: true }">Home</a>
        <a aria-label="Leistung-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/leistungen" [routerLinkActive]="'active-link'" href="">Leistungen</a>
        <a aria-label="Preis-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/preise" [routerLinkActive]="'active-link'" href="">Preise</a>
        <a aria-label="Team-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/team" [routerLinkActive]="'active-link'" href="">Team</a>
        <a aria-label="Kontakt-Seite" (click)="switch(); $event.stopPropagation();" routerLink="/kontakt" [routerLinkActive]="'active-link'" href="">Kontakt</a>
    </div>
</div>