<div class="overdiv">
    <div class="welcomeText">
        <h3>Unsere <span style="font-weight: 500;">Leistungen</span></h3>
        <div class="wrap">
            <div class="card" data-aos="fade-up">
                <img src="./assets/img/Physiotherapie.webp" alt="">
                <div>
                    <h4>Physiotherapie</h4>
                    <span>Ziel einer <b>Physiotherapie</b> ist es Schmerzen zu lindern, Stoffwechsel und Durchblutung zu fördern sowie Beweglichkeit und Kraft zu verbessern.</span>
                </div>
                <div style="margin: 16px;" class="TeamButton">
                    <a aria-label="Mehr zu Physiotherapie" routerLink="/physiotherapie">Mehr zu Physiotherapie</a>
                </div>
            </div>
            <div class="card" data-aos="fade-up">
                <img src="./assets/img/Osteopathie.webp" alt="">
                <div>
                    <h4>Osteopathie</h4>
                    <span>Ziel der <b>Osteopathie</b> ist die Wiederherstellung einer optimalen körperlichen Funktionsfähigkeit durch die manuelle Beseitigung von Blockaden.</span>
                </div>
                <div style="margin: 16px;" class="TeamButton">
                    <a aria-label="Mehr zu Osteopathie" routerLink="/osteopathie">Mehr zu Osteopathie</a>
                </div>
            </div>
            <div class="card" data-aos="fade-up">
                <img src="./assets/img/Naturheilpraxis.webp" alt="">
                <div>
                    <h4>Heilkunde</h4>
                    <span>Die <b>Heilkunde</b> betrachtet den Menschen als Ganzes. So ist das Ziel die Wiederherstellung und Förderung der Selbstheilungskräfte.</span>
                </div>
                <div style="margin: 16px;" class="TeamButton">
                    <a aria-label="Mehr zu Heilkunde" routerLink="/heilkunde">Mehr zu Heilkunde</a>
                </div>
            </div>
        </div>
        
    </div>    
    <app-contact-pre></app-contact-pre>
</div>

