<div  style="width: 100%; display: flex; justify-content: center;">
    <div class="welcomeText div-overdiv" style="padding-top: 80px; text-align: center;">
        <h1>Datenschutzerklärung</h1>
    <p><b>Google Maps<br/></b></p>
    <p>Eine &#220;bersicht der von uns empfohlenen Consent Tools finden Sie hier:<br/>https://www.e-recht24.de/mitglieder/lp-cookieeinwilligung/.<br/></p>
    <p>F&#252;r das Teilen von Inhalten auf Social-Media-Plattformen bietet eRecht24 au&#223;erdem das <i>eRecht24 Safe<br/>Sharing Tool</i> an:<br/>https://www.e-recht24.de/erecht24-safe-sharing.html.<br/></p>
    <p> <br/></p>
    <p><b>Textversion der Datenschutzerkl&#228;rung f&#252;r Ihre Website<br/></b></p>
    <p><b>Datenschutzerkl&#228;rung<br/>1. Datenschutz auf einen Blick<br/>Allgemeine Hinweise<br/></b></p>
    <p>Die folgenden Hinweise geben einen einfachen &#220;berblick dar&#252;ber, was mit Ihren personenbezogenen Daten<br/>passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie<br/>pers&#246;nlich identifiziert werden k&#246;nnen. Ausf&#252;hrliche Informationen zum Thema Datenschutz entnehmen<br/>Sie unserer unter diesem Text aufgef&#252;hrten Datenschutzerkl&#228;rung.<br/></p>
    <p><b>Datenerfassung auf dieser Website<br/>Wer ist verantwortlich f&#252;r die Datenerfassung auf dieser Website?<br/></b></p>
    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten<br/>k&#246;nnen Sie dem Abschnitt &#8222;Hinweis zur Verantwortlichen Stelle&#8220; in dieser Datenschutzerkl&#228;rung entnehmen.<br/></p>
    <p><b>Wie erfassen wir Ihre Daten?<br/></b></p>

    <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um<br/>Daten handeln, die Sie in ein Kontaktformular eingeben.<br/></p>
    <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-<br/>Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit<br/>des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.<br/></p>
    <p><b>Wof&#252;r nutzen wir Ihre Daten?<br/></b></p>
    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&#228;hrleisten. Andere<br/>Daten k&#246;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.<br/></p>
    <p><b>Welche Rechte haben Sie bez&#252;glich Ihrer Daten?<br/></b></p>
    <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &#252;ber Herkunft, Empf&#228;nger und Zweck Ihrer<br/>gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&#223;erdem ein Recht, die Berichtigung oder<br/>L&#246;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,<br/>k&#246;nnen Sie diese Einwilligung jederzeit f&#252;r die Zukunft widerrufen. Au&#223;erdem haben Sie das Recht, unter<br/>bestimmten Umst&#228;nden die Einschr&#228;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&#228;ndigen Aufsichtsbeh&#246;rde zu.<br/></p>
    <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&#246;nnen Sie sich jederzeit an uns wenden.<br/></p>
    <p><b>Analyse-Tools und Tools von Drittanbietern<br/></b></p>
    <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor<br/>allem mit sogenannten Analyseprogrammen.<br/></p>
    <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden<br/>Datenschutzerkl&#228;rung.<br/></p>
    <p><b>2. Hosting<br/></b>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:<br/></p>
    <p><b>IONOS<br/></b></p>
    <p>Anbieter ist die IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (nachfolgend IONOS). Wenn Sie unsere<br/>Website besuchen, erfasst IONOS verschiedene Logfiles inklusive Ihrer IP-Adressen. Details entnehmen Sie<br/>der Datenschutzerkl&#228;rung von IONOS:<br/>https://www.ionos.de/terms-gtc/terms-privacy.<br/></p>
    <p>Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein<br/>berechtigtes Interesse an einer m&#246;glichst zuverl&#228;ssigen Darstellung unserer Website. Sofern eine<br/>entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&#223;lich auf Grundlage von Art.<br/>6 Abs. 1 lit. a DSGVO und &#167; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den<br/>Zugriff auf Informationen im Endger&#228;t des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG<br/>umfasst. Die Einwilligung ist jederzeit widerrufbar.<br/></p>
    <p><b>Auftragsverarbeitung<br/></b></p>
    <p>Wir haben einen Vertrag &#252;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes<br/>geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der<br/>gew&#228;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren<br/></p>

    <p>Weisungen und unter Einhaltung der DSGVO verarbeitet.<br/></p>
    <p><b>3. Allgemeine Hinweise und Pflichtinformationen<br/>Datenschutz<br/></b></p>
    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&#246;nlichen Daten sehr ernst. Wir behandeln Ihre<br/>personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie<br/>dieser Datenschutzerkl&#228;rung.<br/></p>
    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.<br/>Personenbezogene Daten sind Daten, mit denen Sie pers&#246;nlich identifiziert werden k&#246;nnen. Die vorliegende<br/>Datenschutzerkl&#228;rung erl&#228;utert, welche Daten wir erheben und wof&#252;r wir sie nutzen. Sie erl&#228;utert auch, wie<br/>und zu welchem Zweck das geschieht.<br/></p>
    <p>Wir weisen darauf hin, dass die Daten&#252;bertragung im Internet (z. B. bei der Kommunikation per E-Mail)<br/>Sicherheitsl&#252;cken aufweisen kann. Ein l&#252;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht<br/>m&#246;glich.<br/></p>
    <p><b>Hinweis zur verantwortlichen Stelle<br/></b></p>
    <p>Die verantwortliche Stelle f&#252;r die Datenverarbeitung auf dieser Website ist:<br/></p>
    <p>Stefan Paul<br/>Ludwig-Jahn-Str. 8<br/>47918 T&#246;nisvorst<br/></p>
    <p>Telefon: +49 (0) 2151 9424700<br/>E-Mail: info&#64;osteomedica-toenisvorst.de<br/></p>
    <p>Verantwortliche Stelle ist die nat&#252;rliche oder juristische Person, die allein oder gemeinsam mit anderen &#252;ber<br/>die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. &#196;.)<br/>entscheidet.<br/></p>
    <p><b>Speicherdauer<br/></b></p>
    <p>Soweit innerhalb dieser Datenschutzerkl&#228;rung keine speziellere Speicherdauer genannt wurde, verbleiben<br/>Ihre personenbezogenen Daten bei uns, bis der Zweck f&#252;r die Datenverarbeitung entf&#228;llt. Wenn Sie ein<br/>berechtigtes L&#246;schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,<br/>werden Ihre Daten gel&#246;scht, sofern wir keine anderen rechtlich zul&#228;ssigen Gr&#252;nde f&#252;r die Speicherung Ihrer<br/>personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im<br/>letztgenannten Fall erfolgt die L&#246;schung nach Fortfall dieser Gr&#252;nde.<br/></p>
    <p><b>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser<br/>Website<br/></b></p>
    <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf<br/>Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien<br/>nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&#252;cklichen Einwilligung in die &#220;bertragung<br/>personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&#223;erdem auf Grundlage von Art.<br/>49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in<br/>Ihr Endger&#228;t (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zus&#228;tzlich<br/>auf Grundlage von &#167; 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur<br/></p>

    <p>Vertragserf&#252;llung oder zur Durchf&#252;hrung vorvertraglicher Ma&#223;nahmen erforderlich, verarbeiten wir Ihre<br/>Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese<br/>zur Erf&#252;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.<br/>Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f<br/>DSGVO erfolgen. &#220;ber die jeweils im Einzelfall einschl&#228;gigen Rechtsgrundlagen wird in den folgenden<br/>Abs&#228;tzen dieser Datenschutzerkl&#228;rung informiert.<br/></p>
    <p><b>Empf&#228;nger von personenbezogenen Daten<br/></b></p>
    <p>Im Rahmen unserer Gesch&#228;ftst&#228;tigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei<br/>ist teilweise auch eine &#220;bermittlung von personenbezogenen Daten an diese externen Stellen erforderlich.<br/>Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer<br/>Vertragserf&#252;llung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten<br/>an Steuerbeh&#246;rden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe<br/>haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von<br/>Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines g&#252;ltigen<br/>Vertrags &#252;ber Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag &#252;ber<br/>gemeinsame Verarbeitung geschlossen.<br/></p>
    <p><b>Widerruf Ihrer Einwilligung zur Datenverarbeitung<br/></b></p>
    <p>Viele Datenverarbeitungsvorg&#228;nge sind nur mit Ihrer ausdr&#252;cklichen Einwilligung m&#246;glich. Sie k&#246;nnen eine<br/>bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&#228;&#223;igkeit der bis zum Widerruf erfolgten<br/>Datenverarbeitung bleibt vom Widerruf unber&#252;hrt.<br/></p>
    <p><b>Widerspruchsrecht gegen die Datenerhebung in besonderen F&#228;llen sowie gegen<br/>Direktwerbung (Art. 21 DSGVO)<br/></b></p>
    <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO<br/>ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&#220;NDEN, DIE SICH AUS IHRER BESONDEREN<br/>SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN<br/>WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&#220;R EIN AUF DIESE BESTIMMUNGEN GEST&#220;TZTES<br/>PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,<br/>ENTNEHMEN SIE DIESER DATENSCHUTZERKL&#196;RUNG. WENN SIE WIDERSPRUCH EINLEGEN,<br/>WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES<br/>SEI DENN, WIR K&#214;NNEN ZWINGENDE SCHUTZW&#220;RDIGE GR&#220;NDE F&#220;R DIE VERARBEITUNG<br/>NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &#220;BERWIEGEN ODER DIE<br/>VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&#220;BUNG ODER VERTEIDIGUNG VON<br/>RECHTSANSPR&#220;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br/></p>
    <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,<br/>SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE<br/>BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG<br/>EINZULEGEN; DIES GILT AUCH F&#220;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN<br/>VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN<br/>ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH<br/>NACH ART. 21 ABS. 2 DSGVO).<br/></p>
    <p><b>Beschwerderecht bei der zust&#228;ndigen Aufsichtsbeh&#246;rde<br/></b></p>
    <p>Im Falle von Verst&#246;&#223;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer<br/>Aufsichtsbeh&#246;rde, insbesondere in dem Mitgliedstaat ihres gew&#246;hnlichen Aufenthalts, ihres Arbeitsplatzes<br/>oder des Orts des mutma&#223;lichen Versto&#223;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger<br/>verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.<br/></p>

    <p><b>Recht auf Daten&#252;bertragbarkeit<br/></b></p>
    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&#252;llung eines Vertrags<br/>automatisiert verarbeiten, an sich oder an einen Dritten in einem g&#228;ngigen, maschinenlesbaren Format<br/>aush&#228;ndigen zu lassen. Sofern Sie die direkte &#220;bertragung der Daten an einen anderen Verantwortlichen<br/>verlangen, erfolgt dies nur, soweit es technisch machbar ist.<br/></p>
    <p><b>Auskunft, Berichtigung und L&#246;schung<br/></b></p>
    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche<br/>Auskunft &#252;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&#228;nger und den<br/>Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&#246;schung dieser Daten. Hierzu sowie<br/>zu weiteren Fragen zum Thema personenbezogene Daten k&#246;nnen Sie sich jederzeit an uns wenden.<br/></p>
    <p><b>Recht auf Einschr&#228;nkung der Verarbeitung<br/></b></p>
    <p>Sie haben das Recht, die Einschr&#228;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>Hierzu k&#246;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&#228;nkung der Verarbeitung besteht in<br/>folgenden F&#228;llen:<br/></p>
    <p>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&#246;tigen wir<br/>in der Regel Zeit, um dies zu &#252;berpr&#252;fen. F&#252;r die Dauer der Pr&#252;fung haben Sie das Recht, die<br/>Einschr&#228;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&#228;&#223;ig geschah/geschieht, k&#246;nnen Sie<br/>statt der L&#246;schung die Einschr&#228;nkung der Datenverarbeitung verlangen.<br/>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&#246;tigen, Sie sie jedoch zur Aus&#252;bung,<br/>Verteidigung oder Geltendmachung von Rechtsanspr&#252;chen ben&#246;tigen, haben Sie das Recht, statt der<br/>L&#246;schung die Einschr&#228;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&#228;gung zwischen<br/>Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen<br/>&#252;berwiegen, haben Sie das Recht, die Einschr&#228;nkung der Verarbeitung Ihrer personenbezogenen Daten<br/>zu verlangen.<br/></p>
    <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&#228;nkt haben, d&#252;rfen diese Daten &#8211; von<br/>ihrer Speicherung abgesehen &#8211; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&#252;bung oder<br/>Verteidigung von Rechtsanspr&#252;chen oder zum Schutz der Rechte einer anderen nat&#252;rlichen oder<br/>juristischen Person oder aus Gr&#252;nden eines wichtigen &#246;ffentlichen Interesses der Europ&#228;ischen Union oder<br/>eines Mitgliedstaats verarbeitet werden.<br/></p>
    <p><b>SSL- bzw. TLS-Verschl&#252;sselung<br/></b></p>
    <p>Diese Seite nutzt aus Sicherheitsgr&#252;nden und zum Schutz der &#220;bertragung vertraulicher Inhalte, wie zum<br/>Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-<br/>Verschl&#252;sselung. Eine verschl&#252;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von<br/>&#8222;http://&#8220; auf &#8222;https://&#8220; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/></p>
    <p>Wenn die SSL- bzw. TLS-Verschl&#252;sselung aktiviert ist, k&#246;nnen die Daten, die Sie an uns &#252;bermitteln, nicht<br/>von Dritten mitgelesen werden.<br/></p>
    <p><b>Widerspruch gegen Werbe-E-Mails<br/></b></p>
    <p>Der Nutzung von im Rahmen der Impressumspflicht ver&#246;ffentlichten Kontaktdaten zur &#220;bersendung von<br/>nicht ausdr&#252;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die<br/>Betreiber der Seiten behalten sich ausdr&#252;cklich rechtliche Schritte im Falle der unverlangten Zusendung von<br/></p>

    <p>Werbeinformationen, etwa durch Spam-E-Mails, vor.<br/></p>
    <p><b>4. Datenerfassung auf dieser Website<br/>Kontaktformular<br/></b></p>
    <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem<br/>Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage<br/>und f&#252;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre<br/>Einwilligung weiter.<br/></p>
    <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit<br/>der Erf&#252;llung eines Vertrags zusammenh&#228;ngt oder zur Durchf&#252;hrung vorvertraglicher Ma&#223;nahmen<br/>erforderlich ist. In allen &#252;brigen F&#228;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der<br/>effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer<br/>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit<br/>widerrufbar.<br/></p>
    <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&#246;schung<br/>auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&#252;r die Datenspeicherung entf&#228;llt<br/>(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &#8211;<br/>insbesondere Aufbewahrungsfristen &#8211; bleiben unber&#252;hrt.<br/></p>
    <p><b>Anfrage per E-Mail, Telefon oder Telefax<br/></b></p>
    <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus<br/>hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens<br/>bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/></p>
    <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit<br/>der Erf&#252;llung eines Vertrags zusammenh&#228;ngt oder zur Durchf&#252;hrung vorvertraglicher Ma&#223;nahmen<br/>erforderlich ist. In allen &#252;brigen F&#228;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der<br/>effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer<br/>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit<br/>widerrufbar.<br/></p>
    <p>Die von Ihnen an uns per Kontaktanfragen &#252;bersandten Daten verbleiben bei uns, bis Sie uns zur L&#246;schung<br/>auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&#252;r die Datenspeicherung entf&#228;llt<br/>(z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &#8211;<br/>insbesondere gesetzliche Aufbewahrungsfristen &#8211; bleiben unber&#252;hrt.<br/></p>
    <p><b>5. Analyse-Tools und Werbung<br/>IONOS WebAnalytics<br/></b></p>
    <p>Diese Website nutzt die Analysedienste von IONOS WebAnalytics (im Folgenden: IONOS). Anbieter ist die<br/>1&amp;1 IONOS SE, Elgendorfer Stra&#223;e 57, D &#8211; 56410 Montabaur. Im Rahmen der Analysen mit IONOS k&#246;nnen<br/>u. a. Besucherzahlen und &#8211;verhalten (z. B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs,<br/>Absprungraten), Besucherquellen (d. h., von welcher Seite der Besucher kommt), Besucherstandorte sowie<br/>technische Daten (Browser- und Betriebssystemversionen) analysiert werden. Zu diesem Zweck speichert<br/>IONOS insbesondere folgende Daten:<br/></p>
    <p>Referrer (zuvor besuchte Webseite)<br/></p>

    <p>angeforderte Webseite oder Datei<br/>Browsertyp und Browserversion<br/>verwendetes Betriebssystem<br/>verwendeter Ger&#228;tetyp<br/>Uhrzeit des Zugriffs<br/>IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs verwendet)<br/></p>
    <p>Die Datenerfassung erfolgt laut IONOS vollst&#228;ndig anonymisiert, sodass sie nicht zu einzelnen Personen<br/>zur&#252;ckverfolgt werden kann. Cookies werden von IONOS WebAnalytics nicht gespeichert.<br/></p>
    <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der<br/>Websitebetreiber hat ein berechtigtes Interesse an der statistischen Analyse des Nutzerverhaltens, um<br/>sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung<br/>abgefragt wurde, erfolgt die Verarbeitung ausschlie&#223;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &#167;<br/>25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen<br/>im Endger&#228;t des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist<br/>jederzeit widerrufbar.<br/></p>
    <p>Weitere Informationen zur Datenerfassung und Verarbeitung durch IONOS WebAnalytics entnehmen Sie<br/>der Datenschutzerklaerung von IONOS unter folgendem Link:<br/>https://www.ionos.de/terms-gtc/datenschutzerklaerung/<br/></p>
    <p><b>Auftragsverarbeitung<br/></b></p>
    <p>Wir haben einen Vertrag &#252;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes<br/>geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der<br/>gew&#228;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren<br/>Weisungen und unter Einhaltung der DSGVO verarbeitet.<br/></p>
    <p><b>6. Plugins und Tools<br/>Google Maps<br/></b></p>
    <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited (&#8222;Google&#8220;), Gordon<br/>House, Barrow Street, Dublin 4, Irland. Mit Hilfe dieses Dienstes k&#246;nnen wir Kartenmaterial auf unserer<br/>Website einbinden.<br/></p>
    <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese<br/>Informationen werden in der Regel an einen Server von Google in den USA &#252;bertragen und dort gespeichert.<br/>Der Anbieter dieser Seite hat keinen Einfluss auf diese Daten&#252;bertragung. Wenn Google Maps aktiviert ist,<br/>kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten Google Fonts verwenden. Beim<br/>Aufruf von Google Maps l&#228;dt Ihr Browser die ben&#246;tigten Web Fonts in ihren Browsercache, um Texte und<br/>Schriftarten korrekt anzuzeigen.<br/></p>
    <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-<br/>Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt<br/>ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende<br/>Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&#223;lich auf Grundlage von Art. 6 Abs. 1 lit. a<br/>DSGVO und &#167; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf<br/>Informationen im Endger&#228;t des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die<br/>Einwilligung ist jederzeit widerrufbar.<br/></p>
    <p>Die Daten&#252;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&#252;tzt.<br/>Details finden Sie hier:<br/></p>

    <p>https://privacy.google.com/businesses/gdprcontrollerterms/ und<br/>https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.<br/></p>
    <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&#228;rung von Google:<br/>https://policies.google.com/privacy?hl=de.<br/></p>
    <p>Das Unternehmen verf&#252;gt &#252;ber eine Zertifizierung nach dem &#8222;EU-US Data Privacy Framework&#8220; (DPF). Der<br/>DPF ist ein &#220;bereinkommen zwischen der Europ&#228;ischen Union und den USA, der die Einhaltung<br/>europ&#228;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&#228;hrleisten soll. Jedes nach<br/>dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere<br/>Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:<br/>https://www.dataprivacyframework.gov/participant/5780.<br/></p>
    <p>Quelle:<br/>https://www.e-recht24.de<br/></p>
    <p>     
        
</div>
    