<div style="width: 100%; display: flex; justify-content: center;">
    <div class="welcomeText div-overdiv" style="padding-top: 80px; text-align: center;">
        <h3>Impressum</h3>

    <p>Stefan Paul<br>
    Ludwig-Jahn-Str. 8<br>
    47918 Tönisvorst</p>

    <h4>Kontakt</h4>
    <p>Telefon: +49 (0) 2151 9424700<br>
    Telefax: +49 (0) 2151 9424172<br>
    E-Mail: <a aria-label="E-Mail-Adresse" href="mailto:info@osteomedica-toenisvorst.de">info&#64;osteomedica-toenisvorst.de</a></p>

    <h4>Aufsichtsbehörde</h4>
    <p>Kreis Viersen Gesundheitsamt<br>
    Rathausmarkt 3<br>
    41747 Viersen</p>
    <p><a aria-label="kreis-viersen.de/gesundheitsamt" href="https://www.kreis-viersen.de/gesundheitsamt" target="_blank" rel="noopener noreferrer">https://www.kreis-viersen.de/gesundheitsamt</a></p>

    <h4>Berufsbezeichnung und berufsrechtliche Regelungen</h4>
    <p>Berufsbezeichnung:<br>
    Heilpraktiker</p>
    <p>Verliehen in:<br>
    Deutschland</p>
    <p>Es gelten folgende berufsrechtliche Regelungen:</p>
    <p>HeilprG<br>
    einsehbar unter: <a aria-label="gesetze-im-internet.de" href="https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html</a></p>
    <p>MPhG<br>
    einsehbar unter: <a aria-label="gesetze-im-internet.de" href="https://www.gesetze-im-internet.de/mphg/BJNR108400994.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a></p>

    <h4>Angaben zur Berufshaftpflichtversicherung</h4>
    <p><strong>Name und Sitz des Versicherers:</strong><br>
    Continental Sachversicherung AG<br>
    Servicecenter Haftpflicht &amp; Cyber 2<br>
    Ruhrallee 92<br>
    44139 Dortmund</p>
    <p><strong>Geltungsraum der Versicherung:</strong><br>Deutschland</p>

    <h4>EU-Streitschlichtung</h4>
    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a  aria-label="ec.europa.eu/consumers/odr/" href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br>
    Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

    <h4>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h4>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <p>Quelle: <a aria-label="e-recht24.de" href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer">e-recht24.de</a></p>

    </div>
</div>